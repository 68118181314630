<template>
  <div v-if="isLoad" class="modal">
    <div class="div-modal-first">
      <div class="div-load-second">
        <img
          src="@/assets/images/circle.svg"
          alt="click_on"
          class="load-circle"
        >
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="!paymentSessionData" class="locale">
      <div
        ref="language-switcher"
        class="locale-had"
        @click="isShowLocale = !isShowLocale"
      >
        <p ref="language-switcher-text" class="locale-title">{{ locale }}</p>
      <!-- <button @click="opa()"> -->
        <img
          ref="language-switcher-icon"
          src="@/assets/images/angle-down-solid.svg"
          alt="click_on"
          class="locale-img"
          :class="{'locale-img-rot ': isShowLocale}"
        >
        </div>
        <!-- <img
          v-else
          src="@/assets/images/angle-up-solid.svg"
          alt="click_on"
          class="locale-img"
          @click="isShowLocale = !isShowLocale"
        > -->
      <!-- </button> -->
      <div ref="language-switcher-menu" v-if="isShowLocale" class="locale-menu">
        <div class="locale-menu-item" :class="{'locale-menu-item-red': locale === 'de'}" @click.stop.prevent="changeLocale('de')" @touchend.stop.prevent="changeLocale('de')">
          <!-- {{ $t('texts.language_display_names_de') }} -->
          Deutsch
        </div>
        <div class="locale-menu-item" :class="{'locale-menu-item-red': locale === 'fr'}" @click.stop.prevent="changeLocale('fr')" @touchend.stop.prevent="changeLocale('fr')">
          <!-- {{ $t('texts.language_display_names_fr') }} -->
          Français
        </div>
        <div class="locale-menu-item" :class="{'locale-menu-item-red': locale === 'en'}" @click.stop.prevent="changeLocale('en')" @touchend.stop.prevent="changeLocale('en')">
          <!-- {{ $t('texts.language_display_names_en') }} -->
          English
        </div>
      </div>
    </div>
    <div v-if="!paymentSessionData" class="form">
      <div class="div-logo">
        <img
          src="@/assets/images/clickon-logo.svg"
          alt="click_on"
          class="form-logo"
        >
        <p class="form-title-firss-text">
          {{ $t('texts.welcome_text') }}
        </p>
        <p class="form-title-second-text">
          {{ $t('texts.fill_text') }}
        </p>
      </div>
      <div class="form-div">
        <p class="form-p" :class="{'form-text-red': isError && !form.amount}">
          {{ $t('texts.amount_text') }}
        </p>
        <input
          v-model="form.amount"
          :placeholder="$t('texts.amount_placeholder_text')"
          id="amount"
          class="form-input"
          type="number"
          :class="{'form-input-red': isError && !form.amount}"
        >
      </div>
      <div class="form-div">
        <p class="form-p" :class="{'form-text-red': isError && !form.firstName}">
          {{ $t('texts.first_name_text') }}
        </p>
        <input
          v-model.trim="form.firstName"
          :placeholder="$t('texts.first_name_placeholder_text')"
          class="form-input"
          :class="{'form-input-red': isError && !form.firstName}"
        >
      </div>
      <div class="form-div">
        <p class="form-p" :class="{'form-text-red': isError && !form.lastName}">
          {{ $t('texts.last_name_text') }}
        </p>
        <input
          v-model.trim="form.lastName"
          :placeholder="$t('texts.last_name_placeholder_text')"
          class="form-input"
          :class="{'form-input-red': isError && !form.lastName}"
        >
      </div>
      <div class="form-div">
        <p class="form-p" :class="{'form-text-red': isError && !isValidEmail}">
          {{ $t('texts.email_text') }}
        </p>
        <input
          v-model.trim="form.emailAddress"
          :placeholder="$t('texts.enter_email_placeholder_text')"
          class="form-input"
          :class="{'form-input-red': isError && !isValidEmail}"
          type="email"
        >
      </div>
      <div class="form-div">
        <p class="form-p" :class="{'form-text-red': isError && !form.phoneNumber}">
          {{ $t('texts.phone_text') }}
        </p>
        <input
          v-model.trim="form.phoneNumber"
          :placeholder="$t('texts.phone_number_placeholder_text')"
          class="form-input"
          :class="{'form-input-red': isError && !form.phoneNumber}"
        >
      </div>
      <div class="form-div">
        <p class="form-p">
          {{ $t('texts.notes_text')}}
        </p>
        <input
          v-model.trim="form.notes"
          class="form-input"
        >
      </div>
      <div class="div-button">
        <button
          class="form-button"
          @click="sendRequestNew"
        >
          {{ $t('texts.transaction_text') }}
        </button>
      </div>
      <div class="div-secured">
        <p class="secured-text">
          {{ $t('texts.secured_text') }}
        </p>
        <img
          src="@/assets/images/clickon-logo.svg"
          alt="click_on"
          class="form-add-logo"
        >
      </div>

    </div>
    <div v-if="paymentSessionData" id="app">
      <div class="nav" :class="{mobile: isMobile}">
        <div @click="toCancel" class="nav__back-link" :class="{mobile: isMobile}">
          <img src="@/assets/images/header-icon.svg">
          <p class="nav__back-link-text">{{ $t('texts.back') }}</p>
        </div>
        <div class="nav__link-wrapper">
          <a href="https://www.twint.ch/" class="nav__link" :class="{mobile: isMobile}" target="_blank">
            <img v-if="!isMobile && windowWidth > 768" src="@/assets/images/twint_logo_q_pos_bg.png" class="nav__link__logo">
            <!-- <img src="@/assets/images/twint-icon-info.svg" class="nav__link__icon"> -->
          </a>
        </div>
        <div class="nav__link-wrapper-info">
          <a href="https://www.twint.ch/" class="nav__link" :class="{mobile: isMobile}" target="_blank">
            <!-- <img v-if="!isMobile && windowWidth > 768" src="@/assets/images/twint_logo_q_pos_bg.png" class="nav__link__logo"> -->
            <img src="@/assets/images/twint-icon-info.svg" class="nav__link__icon">
          </a>
        </div>
      </div>
      <div v-if="isMobile" class="warning">
        <img src="@/assets/images/warning.svg" alt="" class="warning__icon">
        <p class="warning__message">{{ $t('texts.warning') }}</p>
      </div>
      <div v-if="paymentSessionData" class="header" :class="{mobile: isMobile, 'header--full-mobile': windowWidth < 768 && !isMobile, iphone: isIPhone}">
        <!-- <a v-if="(isMobile && !isIPhone) || (!isMobile && windowWidth < 768)" href="https://www.twint.ch/" class="header__link" target="_blank">
          <img src="@/assets/images/twint_logo_q_pos_bg.png">
        </a> -->
        <div class="header__amount-wrapper" :class="{mobile: isMobile, 'header__amount-wrapper--full-mobile': windowWidth < 768 && !isMobile}">
          <p class="header__amount">{{paymentSessionData.currencyCode}} {{ ((paymentSessionData.amount / 100.0).toFixed(2)).toLocaleString('de-CH') }}</p>
        </div>
        <countdown-timer v-if="isMobile" class="header__timer" :time-limit="timeLimit" @time-up="toCancel" />
      </div>
      <div v-if="paymentSessionData" class="page">
        <appSelect v-if="isMobile" :is-i-phone="isIPhone" :token="token" />
        <div v-if="isMobile" class="page__separate-block">
          <div class="page__separate-block__or"><span>{{ $t('texts.or') }}</span></div>
          <p class="page__separate-block__explanation">{{ $t('texts.explanation') }}</p>
        </div>
        <div class="page__credentials" :class="{mobile: isMobile, 'page__credentials--full-mobile': windowWidth < 768 && !isMobile}">
          <div v-if="!isMobile" class="page__qrcode" :class="{'page__qrcode--full-mobile': windowWidth < 768 && !isMobile}">
            <img v-if="!token" src="@/assets/images/waiting.gif" alt="" class="page__loader" :class="{mobile: isMobile, 'page__loader--full-mobile': windowWidth < 768 && !isMobile}">
            <qrcode-vue v-else :value="token" :size="200" level="L" />
          </div>
          <div class="page__token-and-timer" :class="{mobile: isMobile, 'page__token--full-mobile': windowWidth < 768 && !isMobile}">
            <img v-if="!token" src="@/assets/images/waiting.gif" alt="" class="page__loader" :class="{mobile: isMobile, 'page__loader--full-mobile': windowWidth < 768 && !isMobile}">
            <div class="page__token">
              <p v-for="(number, index) in token" :key="index">{{ number }}</p>
            </div>
            <countdown-timer class="page__timer" v-if="!isMobile" :time-limit="timeLimit" @time-up="toCancel" />
          </div>
          <p v-if="!isMobile && windowWidth < 768">{{ $t('texts.warning') }}</p>
          <div v-if="isMobile || windowWidth < 768" class="page__button" :class="{'page__button--full-mobile': windowWidth < 768 && !isMobile}">
            <Button id="copyButton" @click="copyCode">{{ $t('texts.copy') }}</Button>
          </div>
        </div>
        <div v-if="!isMobile" class="page__description" :class="{'page__description--full-mobile': windowWidth < 768 && !isMobile}">
          <div class="page__description__item" :class="{'page__description__item--full-mobile': windowWidth < 768 && !isMobile}">
            <img src="@/assets/images/twint-logo-footer.svg" class="page__description__image-logo">
            <p class="page__description__text">{{ $t('texts.hint1') }}</p>
          </div>
          <div class="page__description__item" :class="{'page__description__item--full-mobile': windowWidth < 768 && !isMobile}">
            <img src="@/assets/images/twint-icon-qr_code.svg" class="page__description__image">
            <p class="page__description__text">{{ $t('texts.hint2') }}</p>
          </div>
          <div class="page__description__item" :class="{'page__description__item--full-mobile': windowWidth < 768 && !isMobile}">
            <img src="@/assets/images/twint-icon-kamera.svg" class="page__description__image">
            <p class="page__description__text">{{ $t('texts.hint3') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isShowModal" class="modal">
      <div class="div-modal-first">
        <div class="div-modal-second">
          <button class="close-button" @click="closeModal">
            <img class="close-button-img" src="@/assets/images/cross.svg">
          </button>
          <img v-if="paymentSessionStatus === 'paid'" class="check-button-img" src="@/assets/images/check-solid.svg">
          <img v-else-if="erroeMessage" class="exclamation-button-img" src="@/assets/images/exclamation.svg">
          <img v-else class="exclamation-button-img" src="@/assets/images/exclamation.svg">
          <p v-if="erroeMessage" class="modal-text">{{ erroeMessage }}</p>
          <p v-if="paymentSessionStatus === 'paid'" class="modal-text">{{ $t('texts.modal_successful_text') }}</p>
          <p v-if="paymentSessionStatus === 'canceled' || isCancelSession" class="modal-text">{{ $t('texts.modal_canceled_text') }}</p>
          <p v-if="paymentSessionStatus === 'failed'" class="modal-text">{{ $t('texts.modal_failed_text') }}</p>
        </div>
      </div>
    </div>
    <!-- <div v-else class="error">
      Error
    </div> -->
  </div>
</template>

<script>
import appSelect from '@/components/appSelect'
import { getPaymentSession } from '@/helpers/firebase/firestoreRefs'
import QrcodeVue from 'qrcode.vue'
import CountdownTimer from './components/CountdownTimer'

export default {
  name: 'App',
  components: {
    appSelect,
    QrcodeVue,
    CountdownTimer
  },
  data () {
    return {
      locale: 'de',
      token: '',
      isIPhone: false,
      isAndroid: false,
      isShowModal: false,
      isShowLocale: false,
      isLoad: false,
      isError: false,
      isValidEmail: false,
      isCancelSession: false,
      windowWidth: window.innerWidth,
      paymentSessionData: null,
      paymentSessionStatus: '',
      timeLimit: 60,
      paymentSessionId: '',
      erroeMessage: '',
      form: {
        amount: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        emailAddress: '',
        notes: ''
      }
    }
  },
  watch: {
    // 'form.amount': {
    //   immediate: true,
    //   async handler (value) {
    //     if (!value) return
    //     // this.form.amount = Number(this.form.amount).toLocaleString('de-CH')
    //     // console.log(document.querySelector('#amount').value)
    //     console.log(Number(document.querySelector('#amount').value).toLocaleString('de-CH'))
    //     const newVal = Number(document.querySelector('#amount').value).toLocaleString('de-CH')
    //     console.log(newVal)
    //     document.querySelector('#amount').value = 'newVal'
    //     // if (this.form.amount) {
    //     //   console.log(Number(this.form.amount).toLocaleString('de-CH'))
    //     //   document.querySelector('#amount').value = 444
    //     //   console.log(document.querySelector('#amount').value)
    //     // }
    //   }
    // },
    paymentSessionId: {
      immediate: true,
      async handler (value) {
        if (!value) return
        console.log(this.paymentSessionId)
        if (this.paymentSessionId) {
          await getPaymentSession(this.paymentSessionId)
            .onSnapshot((snapshot) => {
              this.paymentSessionData = snapshot.data()
              if (this.paymentSessionData) {
                this.isLoad = false
                // console.log(this.paymentSessionData)
                this.$i18n.locale = this.paymentSessionData.locale
                // this.token = this.paymentSessionData.twintCode
                this.token = this.paymentSessionData.paymentMethodData.code
                if (this.paymentSessionData.validUntil) {
                  // eslint-disable-next-line no-debugger
                  const date1 = new Date()
                  const date2 = this.paymentSessionData.validUntil.toDate()
                  const date3 = parseInt(((date2 - date1) / 1000))
                  // console.log(date3)
                  // console.log(new Date().parse('2017.10.11 10:56:20') / 1000)
                  this.timeLimit = date3
                }
              }
            }, (error) => {
              console.error(error)
            })
        }
      }
    },
    paymentSessionData: {
      immediate: true,
      async handler (value) {
        if (!value) return
        // eslint-disable-next-line no-debugger
        // debugger
        this.sendRequest()
        this.paymentSessionStatus = this.paymentSessionData.status
        if (this.paymentSessionStatus) {
          // eslint-disable-next-line no-debugger
          // debugger
          if (this.paymentSessionData.status === 'paid') {
            this.paymentSessionId = ''
            // eslint-disable-next-line no-debugger
            // debugger
            // window.location.href = this.paymentSessionData.successUrl
            this.form.amount = ''
            this.form.firstName = ''
            this.form.lastName = ''
            this.form.phoneNumber = ''
            this.form.emailAddress = ''
            this.form.notes = ''
            this.paymentSessionData = null
            this.isShowModal = true
            // window.location.pathname = ''
          } else if (this.paymentSessionData.status === 'canceled') {
            // window.location.href = this.paymentSessionData.cancelUrl
            this.paymentSessionData = null
            this.isShowModal = true
            // window.location.pathname = ''
          } else if (this.paymentSessionData.status !== 'paid' && this.paymentSessionData.status !== 'active') {
            this.paymentSessionId = ''
            this.form.amount = ''
            this.form.firstName = ''
            this.form.lastName = ''
            this.form.phoneNumber = ''
            this.form.emailAddress = ''
            this.form.notes = ''
            // window.location.href = this.paymentSessionData.cancelUrl
            this.paymentSessionData = null
            this.isShowModal = true
            // window.location.pathname = ''
          }
        }
      }
    }
  },
  async created () {
    document.addEventListener('click', this.onDocumentClick)
    document.addEventListener('touchend', this.onDocumentClick)
    // eslint-disable-next-line no-debugger
    if (navigator.language || navigator.userLanguage) {
      const newLang = (navigator.userLanguage || navigator.language).substr(0, 2).toLowerCase()
      // console.log(window.navigator.userLanguage)
      // eslint-disable-next-line no-debugger
      // debugger
      if (newLang === 'de' || newLang === 'en' || newLang === 'fr') {
        this.$i18n.locale = newLang
        this.locale = newLang
      } else {
        this.$i18n.locale = 'de'
        this.locale = 'de'
      }
    }
    // this.$i18n.locale = this.paymentSessionData
    // this.$i18n.locale = this.locale
    this.isAndroid = !!navigator.userAgent.toLowerCase().match(/android/i)
    this.isIPhone = !!navigator.userAgent.toLowerCase().match(/iphone/i)
    // await getPaymentSession(this.paymentSessionId)
    //   .onSnapshot((snapshot) => {
    //     this.paymentSessionData = snapshot.data()
    //     if (this.paymentSessionData) {
    //       // console.log(this.paymentSessionData)
    //       // eslint-disable-next-line no-debugger
    //       // debugger
    //       this.$i18n.locale = this.paymentSessionData.locale
    //       // this.token = this.paymentSessionData.twintCode
    //       this.token = this.paymentSessionData.paymentMethodData.code
    //       if (this.paymentSessionData.validUntil) {
    //         // eslint-disable-next-line no-debugger
    //         const date1 = new Date()
    //         const date2 = this.paymentSessionData.validUntil.toDate()
    //         const date3 = parseInt(((date2 - date1) / 1000))
    //         // console.log(date3)
    //         // console.log(new Date().parse('2017.10.11 10:56:20') / 1000)
    //         // eslint-disable-next-line no-debugger
    //         // debugger
    //         this.timeLimit = date3
    //       }
    //     }
    //   }, (error) => {
    //     console.error(error)
    //   })
    if (this.paymentSessionData) {
      this.sendRequest()
    }
  },
  mounted () {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
    // this.token = Math.floor(Math.random() * (99999 - 10000) + 10000).toString();
    // this.token = this.paymentSessionData.twintCode;
  },
  methods: {
    onDocumentClick (event) {
      const languageSwitcher = this.$refs['language-switcher']
      const languageSwitcherIcon = this.$refs['language-switcher-icon']
      const languageSwitcherMenu = this.$refs['language-switcher-menu']
      const languageSwitcherText = this.$refs['language-switcher-text']
      const targetElement = event.target

      if ((targetElement !== languageSwitcher) && (targetElement !== languageSwitcherIcon) && (targetElement !== languageSwitcherMenu) && (targetElement !== languageSwitcherText)) {
        this.isShowLocale = false
      }
    },
    toCancel () {
      this.paymentSessionData = null
      this.isShowModal = true
      this.isCancelSession = true
      // getPaymentSession(this.paymentSessionId)
      //   .update({
      //     status: 'canceled'
      //   })
      //   .then(() => {
      //   })
      //   .catch((error) => console.error(error))
    },
    changeLocale (val) {
      // // eslint-disable-next-line no-debugger
      // debugger
      this.locale = val
      this.$i18n.locale = val
      this.isShowLocale = false
    },
    closeModal () {
      this.isShowModal = !this.isShowModal
      this.paymentSessionStatus = ''
      this.isCancelSession = false
    },
    async sendRequestNew () {
      if (this.form.emailAddress) {
        const re = /^.+@.+\..+$/
        this.isValidEmail = this.form.emailAddress !== '' && re.test(this.form.emailAddress) && this.form.emailAddress.length <= 256
      }
      if (!this.form.amount || !this.form.phoneNumber || !this.form.firstName || !this.form.lastName || !this.form.emailAddress || !this.isValidEmail) {
        this.isError = true
        setTimeout(() => {
          this.isError = false
        }, 5000)
        return
      }
      // eslint-disable-next-line no-debugger
      // debugger
      // const url = `https://twint-autodrop.web.app/getPaymentStatus/${window.location.pathname.substring(1)}`;
      // const url = 'https://momopay.clickon.ch/getPaymentStatus/RXwi2VSssK95komkvXBj'
      const url = 'https://momopay.clickon.ch/createPaymentSession/6kjtkPOCu7FarenOHOg2'
      // console.log(url)
      // console.log(this.form)
      // eslint-disable-next-line no-debugger
      // debugger
      this.isLoad = true
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          // paymentId: this.paymentSessionData.paymentId
          // paymentId: this.paymentSessionData.paymentMethodData.pairing_uuid
          locale: this.locale,
          amount: Number(this.form.amount) * 100,
          customer: {
            email: this.form.emailAddress,
            phone: this.form.phoneNumber,
            firstname: this.form.firstName,
            lastname: this.form.lastName
          },
          payment_intent_data: {
            metadata: {
              note: this.form.notes
            }
          }

        })
      // eslint-disable-next-line no-unused-vars
      })
        .then((res) => {
          const newRes = res
          res.json().then(data => {
            if (newRes.status !== 200 && data.message) {
              this.isLoad = false
              this.erroeMessage = data.message
              this.isShowModal = true
            }
            this.paymentSessionId = data.id
          }).catch(e => {
            // // eslint-disable-next-line no-debugger
            // debugger
            // console.log(e)
            this.isLoad = false
          })
        })
    },

    async sendRequest () {
      if (this.paymentSessionData) {
        const url = this.paymentSessionData.paymentMethodData.statusCheckUrl
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({
            paymentId: this.paymentSessionData.paymentId

          })
        }).then((res) => res.json())
          .then(data => {
            if (this.paymentSessionData) {
              // eslint-disable-next-line no-debugger
              // debugger
              if (this.paymentSessionData.status !== 'paid' && this.paymentSessionData.status !== 'failed') {
                setTimeout(() => {
                  this.sendRequest()
                }, 2000)
              }
            }
          }).catch(e => {
            // eslint-disable-next-line no-debugger
            // debugger
            console.log(e)
            setTimeout(() => {
              this.sendRequest()
            }, 2000)
          })
      }
    },
    toCancelUrl () {
      this.paymentSessionId = ''
      this.form.amount = ''
      this.form.firstName = ''
      this.form.lastName = ''
      this.form.phoneNumber = ''
      this.form.emailAddress = ''
      this.form.notes = ''
      // window.location.href = this.paymentSessionData.cancelUrl
      this.paymentSessionData = null
      this.isShowModal = true
    },
    convertToLocalCurrency (amount) {
      return Number(amount).toLocaleString('de-CH')
    },
    copyCode () {
      // navigator clipboard api needs a secure context (https)
      const buttonText = document.querySelector('#copyButton')
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        navigator.clipboard.writeText(this.token).then(() => {
          // buttonText.innerText = 'Copied!'
          buttonText.innerText = this.$t('texts.copied')
          setTimeout(() => {
            // buttonText.innerText = 'Copy code'
            buttonText.innerText = this.$t('texts.copy')
          }, 5000)
        })
        // this.$refs.copyButton
      } else {
        // text area method
        const textArea = document.createElement('textarea')
        textArea.value = this.token
        // make the textarea out of viewport
        textArea.style.position = 'fixed'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        return new Promise((resolve, reject) => {
          // eslint-disable-next-line prefer-promise-reject-errors
          document.execCommand('copy') ? resolve() : reject()
          textArea.remove()
        }).then(() => {
          // buttonText.innerText = 'Copied!'
          buttonText.innerText = this.$t('texts.copied')
          setTimeout(() => {
            // buttonText.innerText = 'Copy code'
            buttonText.innerText = this.$t('texts.copy')
          }, 2000)
        })
      }
    }
  },
  computed: {
    chAmount () {
      const new213 = Number(this.form.amount)
      return new213.toLocaleString('de-CH')
    },
    isMobile () {
      return this.isIPhone || this.isAndroid
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.onDocumentClick)
    document.removeEventListener('touchend', this.onDocumentClick)
  }
  // directives: {
  //   masked: {
  //     update (el, binding, vNode) {
  //     // eslint-disable-next-line no-debugger
  //       debugger
  //       binding.value = Number(el.value).toLocaleString('de-CH')
  //       // const elem = el
  //       // const { context } = vNode
  //       // if (binding.value !== binding.oldValue && binding.oldValue === '') { // for exist phone number
  //       //   context.parsePhone(elem, true)
  //       // } else if (binding.value === binding.oldValue && context.isChangedFlag) {
  //       //   context.parsePhone(elem, true)
  //       // } else if (binding.value !== binding.oldValue) {
  //       //   elem.addEventListener('input', context.parsePhone)
  //       // } else {
  //       //   return false
  //       // }
  //     }
  //   }
  // }
}
</script>

<style lang='scss'>
.load-circle {
  animation: spin .7s linear infinite;
  height: 80px;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.locale-menu-item-red {
  color: red;
}
.locale-menu-item {
  display: flex;
  position: relative;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  padding: 14px 40px;
  cursor: pointer;
}
.locale-menu {
  position: absolute;
  width: auto;
  height: 140px;
  margin-top: 30px;
  top: 0;
  right: 0;
  border: 2px solid #e0e0e0;
  border-radius: 5px;
  background-color: #fff;
}
.locale {
  position: relative;
  display: -webkit-box;
  // display: -webkit-inline-box;
  // -webkit-justify-content: end;
  // -webkit-box-pack: flex-end;
  // justify-content: end;
  margin-top: 10px;
  margin-right: 10px;
  // right: 0;
  // top: 40px;
}
.locale-title {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase
}
.locale-img {
  margin-left: 10px;
  height: 20px;
  transition-duration: 300ms;
}
.locale-img-rot {
  transform: rotate(180deg);
}
.locale-had {
  display: flex;
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
}
.check-button-img {
  height: 80px;
  margin-top: 10px;
}
.exclamation-button-img {
  height: 80px;
  margin-top: 10px;
}
.modal-text {
  text-align: center;
  margin: auto;
  font-size: 20px;
  line-height: 1.5em;
}
.close-button {
  position: absolute;
  color: red;
  font-weight: 700;
  right: 0px;
  top: 0px;
  border: none;
  background: white;
}
.close-button-img {
  height: 20px;
}
.modal {
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  position: absolute;
}
.div-modal-first {
  // width: 500px;
  // height: 500px;
  // background-color: #fff;
  background: rgba(0, 0, 0, 0.46);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  -webkit-box-pack: center;
  justify-content: center;
  max-width: 100%;
  height: 100%;
  display: flex;
}
.div-modal-second {
  background-color: #fff;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  max-width: 3500px;
  // width: 100%;
  width: 500px;
  height: 200px;
  display: flex;
  position: relative;
  border-radius: 10px;
  padding: 20px;
}
.div-load-second {
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  max-width: 3500px;
  // width: 100%;
  display: flex;
  position: relative;
  border-radius: 10px;
  padding: 20px;
}
.form-div {
  position: relative;
  display: block;
  margin: 0 20px;
}
.secured-text {
  font-size: 15px;
  font-weight: 500;
  margin: auto 0;
}
.div-secured {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.form-button {
  width: 100%;
  height: 60px;
  border-radius: 5px;
  color: white;
  // background-color: #3ab75e;
  background-color: #444;
  border: none;
  font-size: 15px;
  font-weight: 600;
}
.form-title-firss-text {
  font-size: 23px;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0.8em 15px;
}
.form-title-second-text {
  font-size: 17px;
  font-weight: 400;
  margin: 0 36px;
  line-height: 1.3em;
}
.div-button {
  margin-top: 10px;
  text-align: center;
  position: relative;
  display: block;
  margin: 0 20px;
}
.div-logo {
  text-align: center;
  margin-bottom: 30px;
}
.form-logo {
  max-width: 150px;
}
.form-add-logo {
  margin-left: 10px;
  max-width: 150px;
}
.form-p {
  margin: 5px 0;
  color: #595959;
  font-size: 17px;
}
// .form-input::placeholder {
//   padding-left: 15px;
// }
.form-input-red {
  border-color: red !important;
}
.form-text-red {
  color: red !important;
}
.form-input {
  // border: solid;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 100%;
  width: 100%;
  height: 45px;
  font-size: 17px;
  padding-left: 15px;
  border: 2px solid #e0e0e0;
  border-radius: 0.125rem;
  // border-width: 2px;
  // border-color: var(--color-gray-300);
  line-height: inherit;
  color: inherit;
  transition: border 0.3s;
  appearance: none;
  margin-bottom: 20px;
}
.form {
  z-index: -20;
  max-width: 500px;
  margin: auto;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
}
.hide {
  display: none;
}
.error {
  display: flex;
  height: 83px;
  align-items: center;
  justify-content: space-evenly;
  background-color: $white;
  border-bottom: solid;
  border-bottom-width: 3px;
  border-image: url(assets/images/line.png) 30 stretch;
  padding: 0 28px;
  font-size: 58px;
}

.nav {
  display: flex;
  height: 83px;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  border-bottom: solid;
  border-bottom-width: 3px;
  border-image: url(assets/images/line.png) 30 stretch;
  padding: 0 28px;

  &.mobile {
    height: 66px;
  }

  &__back-link {
    display: flex;
    width: 30%;
    color: black;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      color: $black;
    }

    &.mobile {
      width: 100%;
    }

    img {
      margin-right: 12px;
    }

    p {
      font-size: 16px;
    }
  }

  // &__back-link-text {
  //   color: black;
  //   text-decoration: none;
  // }

  &__link-wrapper {
    display: flex;
    height: auto;
    // width: 63%;
  }
  &__link-wrapper-info {
    display: flex;
    height: auto;
    width: 30%;
    justify-content: flex-end;
    // width: 63%;
  }

  &__link {
    // width: 100%;
    display: flex;

    &.mobile {
      margin-left: auto;
    }

    &__logo {
      width: 140px;
      height: 54px;
      // margin-left: 80px;
    }

    &__icon {
      margin-left: auto;
    }
  }
}

.warning {
  display: flex;
  align-items: center;
  padding: 13px 17px;
  background-color: rgb(245, 166, 35);

  &__message {
    color: white;
    font-weight: bold;
    margin: 0 0 0 13px;
  }
}

.header {
  display: flex;
  justify-content: center;
  padding: 52px 0;

  &.mobile {
    flex-direction: column;
    align-items: center;
    padding: 17px;

    &.iphone {
      margin-top: 32px;
    }
  }

  &__timer {
    margin-top: 20px;
  }

  &__link {
    align-self: flex-start;
    margin-bottom: 40px;

    img {
      width: 140px;
    }
  }

  &__amount-wrapper {
    font-family: 'Roboto', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: $white;
    font-size: 54px;
    font-weight: bold;
    background-color: $black;
    padding: 9px 26px;

    &.mobile {
      font-size: 45px;
      width: 243px;
      padding: 8px 14px;
    }

    &--full-mobile {
      width: fit-content;
      align-self: center;
      padding: 8px 20px;

      p {
        font-weight: bold;
        font-size: 45px;
      }
    }
  }

  &__amount {
    margin: 0;
    line-height: 58px;
    text-align: center;
  }

  &--full-mobile {
    display: flex;
    flex-direction: column;
    padding-bottom: 17px;

    a {
      margin-left: 30px;
    }
  }
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__description {
    width: 100%;
    display: flex;
    max-width: 800px;
    justify-content: space-between;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      max-width: 234px;

      &--full-mobile {
        flex-direction: row;
        width: 344px;
        max-width: unset;
        margin-bottom: 30px;

        p {
          text-align: start;
        }

        img {
          margin-right: 20px;
          width: 46px;
        }

        .page__description__image-logo {
          width: 45px;
          height: 34px;
        }
      }
    }

    &__image {
      height: 46px;
    }

    &__image-logo {
      padding: 6px;
      background-color: $black;
      border-radius: 10px;
      width: 33px;
    }

    &__text {
      text-align: center;
    }

    &--full-mobile {
      flex-direction: column;
      align-items: center;
    }
  }

  &__credentials {
    display: flex;
    justify-content: space-between;
    width: 635px;
    padding-bottom: 48px;

    &.mobile {
      width: 100%;
      padding: 0;
      background-color: #fff;
      flex-direction: column;
      align-items: center;
      padding-bottom: 34px;
    }

    &--full-mobile {
      // justify-content: center;
      flex-direction: column;
      align-items: center;
      width: auto;

      > p {
        text-align: center;
        padding: 0 40px;
      }
    }
  }

  &__loader {
    &--full-mobile,
    &.mobile {
      width: 130px;
      height: 115px;
    }
  }

  &__qrcode {
    background-color: $white;
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }

    &--full-mobile {
      display: none;
    }
  }
  &__timer {
    margin-bottom: 30px;
  }
  &__token-and-timer {
    width: 300px;
    // height: 300px;
    // min-height: 300px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // &.mobile {
    //   height: auto;
    // }
  }

  &__token {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &.mobile {
      width: 100%;
      // height: 117px;
    }

    p {
      background-color: $grey-1;
      font-family: 'Roboto', Arial, 'Helvetica Neue', Helvetica, sans-serif;
      font-size: 36px;
      font-weight: normal;
      color: $black;
      height: 54px;
      width: 44px;
      line-height: 56px;
      text-align: center;
      margin-right: 6px;

      &:last-child {
        margin-right: unset;
      }
    }

    &--full-mobile {
      height: auto;
      width: 352px;
      margin-bottom: 20px;
    }
  }

  &__button {
    button {
      font-size: 19px;
      border-radius: 5px;
      text-decoration: none;
      background: #ffffff;
      border: 1px solid #000000;
      padding: 10px 20px 10px 20px;
      color: #000000;
      min-height: 43px;
      font-family: robotoRegular, Arial, 'Helvetica Neue', Helvetica, sans-serif;
    }

    &--full-mobile {
      button {
        background-color: #262626;
        color: #fff;
        padding: 14px 50px;
      }
    }
  }

  &__separate-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__or {
      display: flex;
      width: 88%;
      margin: 30px 0px;

      &:before {
        content: '';
        flex: 1 1;
        border-bottom: 2px solid rgb(216, 216, 216);
        margin: auto;
      }

      &:after {
        content: '';
        flex: 1 1;
        border-bottom: 2px solid rgb(216, 216, 216);
        margin: auto;
      }

      span {
        padding: 0 16px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
      }
    }

    &__explanation {
      color: black;
      font-size: 20px;
      padding-top: 10px;
      line-height: 23px;
      margin-top: 0;
      text-align: center;
    }
  }
}

@media (max-width: 768px) {

}
</style>
