import { FBFirestoreRef } from '@/helpers/firebase/services'

// users collection

export function getPaymentSessions () {
  return FBFirestoreRef
    .collection('paymentSessions')
}
export function getPaymentSession (paymentSessionId) {
  return FBFirestoreRef
    .collection('paymentSessions')
    .doc(paymentSessionId)
}
