<template>
  <div class="base-timer">
    <svg
      class="base-timer__svg"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g class="base-timer__circle">
        <circle
          class="base-timer__path-elapsed"
          cx="50"
          cy="50"
          r="46.5"
        />
        <path
          :stroke-dasharray="circleDasharray"
          :class="remainingPathColor"
          class="base-timer__path-remaining"
          d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
        "/>
      </g>
    </svg>
    <span class="base-timer__label">
      {{ formattedTimeLeft }}
    </span>
  </div>
</template>

<script>
export default {
  data () {
    return {
      timePassed: 0,
      timerInterval: null
    }
  },
  props: {
    timeLimit: {
      type: Number,
      default: 60
    }
  },
  mounted () {
    this.startTimer()
  },
  methods: {
    startTimer () {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000)
    }
  },
  computed: {
    timeLeft () {
      return this.timeLimit - this.timePassed
    },
    formattedTimeLeft () {
      const timeLeft = this.timeLeft
      const minutes = Math.floor(timeLeft / 60)
      let seconds = timeLeft % 60
      if (seconds < 10) {
        seconds = `0${seconds}`
      }
      if (seconds > -1) {
        return `${minutes}:${seconds}`
      } else {
        this.$emit('time-up')
        return ''
      }
    },
    circleDasharray () {
      return `${(this.timeFraction * 283).toFixed(0)} 283`
    },
    timeFraction () {
      const rawTimeFraction = this.timeLeft / this.timeLimit
      return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction)
    },
    colorCodes () {
      return {
        info: {
          color: 'green'
        },
        warning: {
          color: 'orange',
          threshold: 10
        },
        alert: {
          color: 'red',
          threshold: 5
        }
      }
    },
    remainingPathColor () {
      const { alert, warning, info } = this.colorCodes
      if (this.timeLeft <= alert.threshold) {
        return alert.color
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color
      } else {
        return info.color
      }
    }
  }
}
</script>

<style scoped lang="scss">
.base-timer {
  position: relative;
  width: 130px;
  height: 130px;

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke:white;
  }

  &__label {
    position: absolute;
    width: 130px;
    height: 130px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
  }

  // &__svg {
  //   // Flips the svg and makes the animation to move left-to-right
  //   transform: scaleX(-1);
  // }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }
}
</style>
