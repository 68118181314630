import en from './languages/en.json'
import de from './languages/de.json'
import fr from './languages/fr.json'

export const defaultLocale = 'en'

export const languages = {
  en: en,
  de: de,
  fr: fr
}
