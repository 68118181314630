import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/performance'
import 'firebase/firestore/memory'

const FBApp = firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
})

// IMPORTANT: Importing firebase/auth directly causes issues (See #52311 and #49502) in Safari
let FBAuth = null
export async function getFBAuth () {
  if (!FBAuth) {
    await import('firebase/auth')
      .then(() => {
        FBAuth = FBApp.auth()
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return FBAuth
}

// Firestore service
export const FBFirestoreRef = FBApp.firestore()

// Storage service
export const FBStorageRef = firebase.storage().ref()

// Performance Monitoring
if (process.env.NODE_ENV === 'production') {
  firebase.performance()
}

// Global Firebase module
export const FBModule = firebase
