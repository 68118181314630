<template>
  <div class="app-select">
    <div v-if="isIPhone" class="app-select--iPhone">
      <p class="app-select__explanation">{{ $t('texts.selectApp') }}</p>
      <div class="app-select__apps-wrapper">
        <div
          v-for="(bank, index) in banks"
          :key="index" :class="`bank-${bank.name}`"
          class="app-select__bank-logo"
        >
        <a :href='`twint-issuer${bank.appNum}://applinks/?al_applink_data={"app_action_type": "TWINT_PAYMENT","extras": {"code": "${token}",},"referer_app_link": {"target_url": "", "url": "", "app_name": "EXTERNAL_WEB_BROWSER"}, "version": "6.0" }`'>
          <img :src="require(`@/assets/images/${bank.name}.svg`)" alt="">
        </a>
        </div>
      </div>
      <div class="app-select__select">
        <select id="app-chooser" v-model="twintBank">
          <option value disabled selected>{{ $t('texts.banks') }}</option>
          <option v-for="(bank, index) in otherBanks" :key="`${index}oth`" :value="bank.appNum">{{ bank.name }}</option>
        </select>
      </div>
    </div>
    <button
      :class="{'app-select__switch-button_disabled': !twintBank && isIPhone, 'app-select__switch-button': twintBank || !isIPhone}"
      :disabled="!twintBank && isIPhone"
      @click="goToTWINTApp"
    >
      {{ $t('texts.switchApp') }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    isIPhone: {
      type: Boolean,
      default: false
    },
    token: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      banks: [
        { name: 'ubs', appNum: '2' },
        { name: 'raiffeisen', appNum: '6' },
        { name: 'pf', appNum: '7' },
        { name: 'zkb', appNum: '3' },
        { name: 'cs', appNum: '4' },
        { name: 'bcv', appNum: '5' }
      ],
      otherBanks: [
        { name: 'AppKB TWINT', appNum: '12' },
        { name: 'BCF TWINT', appNum: '16' },
        { name: 'BCGE TWINT', appNum: '10' },
        { name: 'BCJ TWINT', appNum: '17' },
        { name: 'BCN TWINT', appNum: '18' },
        { name: 'BCVs TWINT', appNum: '13' },
        { name: 'GKB TWINT', appNum: '15' },
        { name: 'GLKB TWINT', appNum: '22' },
        { name: 'Migrosbank TWINT', appNum: '21' },
        { name: 'NAB TWINT', appNum: '11' },
        { name: 'OKB TWINT', appNum: '8' },
        { name: 'SGKB TWINT', appNum: '14' },
        { name: 'TKB TWINT', appNum: '19' },
        { name: 'TWINT – andere Banken & Prepaid', appNum: '1' },
        { name: 'Valiant TWINT', appNum: '20' },
        { name: 'ZugerKB TWINT', appNum: '9' }
      ],
      twintBank: ''
    }
  },
  methods: {
    goToTWINTApp () {
      if (this.isIPhone) {
        window.location = `twint-issuer${this.twintBank}://applinks/?al_applink_data={"app_action_type": "TWINT_PAYMENT","extras": {"code": "${this.token}",},"referer_app_link": {"target_url": "", "url": "", "app_name": "EXTERNAL_WEB_BROWSER"}, "version": "6.0" }`
      } else {
        window.location = `intent://payment#Intent;action=ch.twint.action.TWINT_PAYMENT;scheme=twint;S.code=${this.token};S.startingOrigin=EXTERNAL_WEB_BROWSER;S.browser_fallback_url=https://play.google.com/store/search?q=twint&c=apps;end`
      }
    }
  }
}
</script>

<style lang="scss">
.app-select {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--iPhone {
    width: 100%;
  }

  &__apps-wrapper {
    margin: 0 auto;
    margin-bottom: 32px;
    width: 250px;
    display: grid;
    grid-template-columns: 60px 60px 60px;
    grid-gap: 32px;
    grid-row-gap: 24px;
  }

  &__explanation {
    text-align: center;
    font-size: 20px;
    color: #000;
    margin-top: 30px;
    margin-bottom: 24px;
  }

  &__bank-logo {
    filter: drop-shadow(0px 0px 4px #929292);
    img {
      width: 60px;
    }
  }

  &__select {
    width: 100%;
    background-color: #fff;
    margin-bottom: 12px;
    // text-align: center;
    align-items: center;

    select {
      min-height: 56px;
      padding: 0 20px 1px 18px;
      width: 100%;
      // text-align-last: center;
      text-align: center;
      align-items: center;
      border: none !important;
      border-radius: 0 !important;
      font-family: robotoRegular, Arial, 'Helvetica Neue', Helvetica, sans-serif;
      font-size: 19px;
      -webkit-appearance: none;
      background: url(../assets/images/arrow.svg) no-repeat right 12px top 22px;
      background-color: #fff;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }

  &__switch-button {
    border-radius: 5px;
    font-family: robotoRegular, Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: #FFF;
    font-size: 19px;
    background: #262626;
    padding: 14px 50px 14px 60px;
    text-decoration: none;
    border: 0px;
    min-height: 56px;
    width: 88%;
    margin: 10px 0;
  }

  &__switch-button_disabled {
    border-radius: 5px;
    font-family: robotoRegular, Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: rgb(190, 190, 190);
    font-size: 19px;
    background: #969696;
    padding: 14px 50px 14px 60px;
    text-decoration: none;
    border: 0px;
    min-height: 56px;
    width: 88%;
    margin: 10px 0;
  }
}
</style>
