import Vue from 'vue'
import App from './App'
import VueI18n from 'vue-i18n'

import { languages, defaultLocale } from './i18n/index'

const messages = Object.assign(languages)

Vue.config.productionTip = false
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: defaultLocale,
  messages
})

new Vue({
  render: h => h(App),
  i18n
}).$mount('#app')
